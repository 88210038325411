@charset "utf-8";

//-----------------------------------------------
// spvw
//-----------------------------------------------
@function spvw( $size ) {
	@return calc( 100vw / $sp-base * $size );
}

//-----------------------------------------------
// tbvw
//-----------------------------------------------
@function tbvw( $size ) {
	@return calc( 100vw / $tb-base * $size );
}

//-----------------------------------------------
// pcvw
//-----------------------------------------------
@function pcvw( $size ) {
	@return calc( 100vw / $pc-base * $size );
}

@function pcvw2( $size ) {
	@return calc( 100vw / $pc-base2 * $size );
}

//-----------------------------------------------
// spgrid
//-----------------------------------------------
@function spgrid( $num ) {
	@return calc( 100vw * $sp-grid * $num / $sp-base );
}

//-----------------------------------------------
// pcgrid
//-----------------------------------------------
@function pcgrid( $num ) {
	@return calc( 100vw * $pc-grid * $num / $pc-base );
}

@function pcgridpx( $num ) {
	@return calc( 1px * $pc-grid * $num );
}

//-----------------------------------------------
// hover
//-----------------------------------------------
@mixin hover( $opacity:.7 ) {
	transition: opacity .2s linear;
	opacity: 1;
	
	&:hover {
		opacity: $opacity;
	}
}

//-----------------------------------------------
// clearfix
//-----------------------------------------------
@mixin clearfix() {
	*zoom: 1;
	
	&:after {
		content: "";
		display: block;
		clear: both;
	}
}

//-----------------------------------------------
// scrollbar-hidden
//-----------------------------------------------
@mixin scrollbar-hidden() {
	-ms-overflow-style: none;
	scrollbar-width: none;
	
	&::-webkit-scrollbar {
		display: none;
	}
}