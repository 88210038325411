.p-blog {
	&__container {
		@media #{$tb} {
			display: flex;
			justify-content: space-between;
		}
		&:not(:first-child) {
			margin-top: spvw(30);
		}
	}
	&__main {
		@media #{$tb} {
			width: calc( 100% - 340px );
		}
	}
	&__side {
		@media #{$tb} {
			width: 300px;
		}
	}
	&-archive {
		@media #{$tb} {
			display: flex;
			flex-wrap: wrap;
		}
		&:not(:first-child) {
			margin-top: spvw(40);
			
			@media #{$tb} {
				margin-top: 40px;
			}
		}
	}
	&-item {
		@media #{$sp} {
			&:not(:first-child) {
				margin-top: spvw(30);
			}
		}
		@media #{$tb} {
			width: calc( 100% * 480 / 1000 );
			
			&:nth-child(n+3) {
				margin-top: calc( 100% * 40 / 1000 );
			}
			&:not(:nth-child(2n+1)) {
				margin-left: calc( 100% * 40 / 1000 );
			}
		}
		& > a {
			display: block;
			
			&:hover,
			&:active {
				opacity: 1;
			}
		}
		&__thumb {
			position: relative;
			display: block;
			overflow: clip;
			
			> img {
				display: block;
				backface-visibility: hidden;
				transition: transform .4s;
			}
		}
		& > a:hover &__thumb {
			> img {
				transform: scale(1.08);
			}
		}
		&__caption {
			position: absolute;
			left: 1em;
			bottom: 0.75em;
			right: 1em;
			max-height: 3em;
			color: $color-text-white;
			font-size: spvw(18);
			font-weight: $fw-medium;
			line-height: 1.5;
			transition: opacity .5s;
			
			@media #{$tb} {
				font-size: 16px;
			}
		}
		& > a:hover &__caption {
			opacity: 0.8;
		}
		&__info {
			margin-top: spvw(16);
			
			@media #{$tb} {
				margin-top: 16px;
			}
		}
		&__heading {
			display: flex;
		}
		&__date {
			font-size: spvw(14);
			
			@media #{$tb} {
				font-size: 14px;
			}
			&:before {
				display: inline-block;
				margin-right: 0.25em;
				vertical-align: -0.125em;
				font-family: $ff-icon;
				content: "\f469";
			}
		}
		&__cats {
			display: flex;
			flex-wrap: wrap;
			margin-left: 1em;
			font-size: spvw(14);
			
			@media #{$tb} {
				font-size: 14px;
			}
			li {
				margin-right: 0.75em;
				color: #bf9d87;
			}
		}
		&__excerpt {
			margin-top: 0.5em;
			font-size: spvw(14);
			line-height: 2;
			
			@media #{$tb} {
				font-size: 14px;
			}
		}
	}
	&-archive.is-wide &-item {
		@media #{$tb} {
			width: calc( 100% * 352 / 1100 );
			
			&:nth-child(n+3) {
				margin-top: 0;
			}
			&:nth-child(n+4) {
				margin-top: calc( 100% * 40 / 1100 );
			}
			&:not(:nth-child(2n+1)) {
				margin-left: 0;
			}
			&:not(:nth-child(3n+1)) {
				margin-left: calc( 100% * 22 / 1100 );
			}
		}
		
	}
}