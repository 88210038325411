.m-slider {
	position: relative;
	
	&__container {
		overflow: hidden;
		overflow-x: auto;
		@include scrollbar-hidden;
		scroll-behavior: smooth;
	}
	&__slide {
		display: inline-flex;
	}
	&__prev,
	&__next {
		position: absolute;
		top: spvw(-75);
		display: block;
		appearance: none;
		width: spvw(31);
		height: spvw(31);
		border: 0;
		border-radius: spvw(16);
		background: #605640;
		user-select: none;
		cursor: pointer;
		
		@media #{$tb} {
			top: -75px;
			width: 31px;
			height: 31px;
			border-radius: 16px;
		}
		&:before {
			position: absolute;
			left: spvw(9);
			top: spvw(10.4);
			width: spvw(12.532);
			height: spvw(10.892);
			background: url(#{$img}common/ico-arw.svg) no-repeat;
			background-size: 100% 100%;
			content: '';
			
			@media #{$tb} {
				left: 9px;
				top: 10px;
				width: 12px;
				height: 10px;
			}
		}
		&[disabled] {
			opacity: 0.2;
			pointer-events: none;
			cursor: default;
		}
	}
	&__prev {
		right: spvw(49);
		
		@media #{$tb} {
			right: 65px;
		}
		@media #{$pc} {
			right: 124px;
		}
		&:before {
			transform: scaleX(-1);
		}
	}
	&__next {
		right: spvw(10);
		
		@media #{$tb} {
			right: 26px;
		}
		@media #{$pc} {
			right: 85px;
		}
	}
}