.m-form {
	&:not(:first-child) {
		margin-top: spvw(40);
		
		@media #{$tb} {
			margin-top: 40px;
		}
		@media #{$pc} {
			max-width: 756px;
			margin-top: 80px;
			margin-left: auto;
			margin-right: auto;
		}
	}
	&__container {
		> dl {
			&:not(:first-child) {
				margin-top: spvw(20);
				
				@media #{$tb} {
					margin-top: 20px;
				}
			}
			dt {
				font-size: spvw(14);
				font-weight: $fw-regular;
				
				@media #{$tb} {
					font-size: 14px;
				}
				@media #{$pc} {
					font-size: 16px;
				}
				small {
					margin-left: 0.2em;
					vertical-align: middle;
					color: #CB1616;
					font-size: 100%;
				}
			}
			dd {
				margin-top: spvw(4);
				
				@media #{$tb} {
					margin-top: 4px;
				}
			}
		}
		table {
			font-size: spvw(14);
			font-weight: $fw-regular;
			
			@media #{$tb} {
				font-size: 14px;
			}
			@media #{$pc} {
				font-size: 16px;
			}
			th,
			td {
				padding-top: 0.25em;
				padding-bottom: 0.25em;
			}
			th {
				padding-right: 1em;
				font-weight: $fw-regular;
			}
		}
	}
	&__acceptance {
		margin-top: spvw(24);
		text-align: center;
		
		@media #{$tb} {
			margin-top: 24px;
		}
		@media #{$pc} {
			margin-top: 48px;
		}
	}
	&__submit {
		margin-top: spvw(28);
		text-align: center;
		
		@media #{$tb} {
			margin-top: 28px;
		}
		@media #{$pc} {
			margin-top: 32px;
		}
	}
	input[type="text"],
	input[type="number"],
	input[type="tel"],
	input[type="email"],
	input[type="date"],
	textarea,
	select {
		padding: 1em;
		background: #FBFBFB;
		border: 1px solid #C1C1C1;
		border-radius: spvw(5);
		box-sizing: border-box;
		font-size: spvw(14);
		font-weight: $fw-regular;
		
		@media #{$tb} {
			border-radius: 5px;
			font-size: 14px;
		}
		@media #{$pc} {
			font-size: 16px;
		}
	}
	
	input[type="text"],
	input[type="number"],
	input[type="tel"],
	input[type="email"],
	textarea {
		display: block;
		-webkit-appearance: none;
		appearance: none;
		width: 100%;
	}
	input[type="date"],
	select {
		display: inline-block;
		-webkit-appearance: none;
		appearance: none;
	}
	textarea {
		resize: vertical;
	}
	input[type="checkbox"],
	input[type="radio"] {
		margin-right: 0.75em;
	}
	input[type="submit"] {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		min-width: spvw(230);
		height: spvw(58);
		padding-left: 1.5em;
		padding-right: 1.5em;
		appearance: none;
		border: 0;
		border-radius: spvw(29);
		background: #9E845B;
		color: #fff;
		font-size: spvw(20);
		font-family: $ff-default;
		font-weight: $fw-regular;
		cursor: pointer;
		
		@media #{$tb} {
			min-width: 230px;
			height: 58px;
			border-radius: 29px;
			font-size: 20px;
		}
		@media #{$pc} {
			min-width: 230px;
			height: 58px;
			border-radius: 29px;
			font-size: 20px;
		}
		&[disabled] {
			background-color: #C1C1C1;
			cursor: default;
		}
	}
	.wpcf7-spinner {
		margin: 0 0 0 -24px;
		left: -8px;
		top: 0.6em;
	}
}