@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Noto+Serif+JP:wght@200..900&display=swap');

$fc-default: $color-text-black;

$ff-sans: "Noto Sans JP", sans-serif;
$ff-serif: "Noto Serif JP", serif;
$ff-en: Georgia, Times, "Times New Roman", serif;
$ff-icon: dashicons;
// $ff-awesome: "Font Awesome 6 Free";
$ff-awesome: "FontAwesome";
$ff-default: $ff-sans;

$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;
$fw-black: 900;
