html {
	height: 100%;
	overflow: hidden;
	overflow-y: scroll;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	
	@media #{$sp} {
		font-size: spvw(10);
	}
	@media #{$tb} {
		font-size: 62.5%;
	}
}