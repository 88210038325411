.l-spnav {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	
	@media #{$pc} {
		display: none;
	}
	body.is-menu-visible & {
		pointer-events: auto;
	}
	&__bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: #F1EADF;
		opacity: 0;
		transition: opacity .5s;
		
		body.is-menu-visible & {
			opacity: 0.8;
		}
	}
	&__container {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow: scroll;
		opacity: 0;
		transition: opacity .5s;
		
		body.is-menu-visible & {
			opacity: 0.8;
		}
	}
	&__menu {
		padding: spvw(110) spvw(24) spvw(60);
		
		> ul {
			text-align: right;
			font-size: spvw(22);
			line-height: 1.2;
			
			> li {
				&:not(:first-child) {
					margin-top: spvw(20);
				}
			}
		}
	}
	&__switch {
		position: absolute;
		right: spvw(22);
		top: spvw(24);
		width: spvw(63);
		height: spvw(63);
		border-radius: spvw(63 * 0.5);
		background-color: #000;
		pointer-events: auto;
		cursor: pointer;
		
		@media #{$tb} {
			right: 22px;
			top: 24px;
			width: 63px;
			height: 63px;
			border-radius: calc( 63px * 0.5 );
		}
		.border {
			position: absolute;
			left: spvw(17.5);
			top: 50%;
			display: block;
			width: spvw(28);
			height: spvw(2);
			background-color: #fff;
			
			@media #{$tb} {
				left: 17.5px;
				width: 28px;
				height: 2px;
			}
			&:nth-child(1) {
				transition: opacity .2s;
				
				body.is-menu-visible & {
					opacity: 0;
				}
			}
			&:nth-child(2) {
				transform: translateY(spvw(-12));
				transition: transform .2s;
				
				@media #{$tb} {
					transform: translateY(-12px);
				}
				body.is-menu-visible & {
					transform: translateY(0vw) rotate(-45deg);
					
					@media #{$tb} {
						transform: translateY(0px) rotate(-45deg);
					}
				}
			}
			&:nth-child(3) {
				transform: translateY(spvw(12));
				transition: transform .2s;
				
				@media #{$tb} {
					transform: translateY(12px);
				}
				body.is-menu-visible & {
					transform: translateY(0vw) rotate(45deg);
					
					@media #{$tb} {
						transform: translateY(0px) rotate(45deg);
					}
				}
			}
		}
	}
}