/*===============================================
 animation
===============================================*/
@keyframes anim-kv-1-sp {
	0% { background-position: 0vw 0px; }
	100% { background-position: spvw(3945) 0px; }
}
@keyframes anim-kv-1-tb {
	0% { background-position: 0vw 0px; }
	100% { background-position: tbvw(2618) 0px; }
}
@keyframes anim-kv-1-pc {
	0% { background-position: 0px 0px; }
	100% { background-position: 2618px 0px; }
}

@keyframes anim-kv-2-sp {
	0% { background-position: 0vw 0px; }
	100% { background-position: spvw(-3907) 0px; }
}
@keyframes anim-kv-2-tb {
	0% { background-position: 0vw 0px; }
	100% { background-position: tbvw(-2604) 0px; }
}
@keyframes anim-kv-2-pc {
	0% { background-position: 0px 0px; }
	100% { background-position: -2604px 0px; }
}

@keyframes anim-kv-beat {
	0% { transform: scale(1); }
	90% { transform: scale(1); }
	95% { transform: scale(1.1); }
	100% { transform: scale(1); }
}