@charset "utf-8";

/*===============================================
 responsive element
===============================================*/
.sp-inline {
	display: inline !important;
}
.sp-inline-block {
	display: inline-block !important;
}
.sp-block {
	display: block !important;
}
.sp-flex {
	display: flex !important;
}

.tb-inline,
.tb-inline-block,
.tb-block,
.tb-flex {
	display: none !important;
}

.tb-only-inline,
.tb-only-inline-block,
.tb-only-block,
.tb-only-flex {
	display: none !important;
}

.menu-br {
	display: none !important;
}

.pc-inline,
.pc-inline-block,
.pc-block,
.pc-flex {
	display: none !important;
}

@media #{$tb} {
	.sp-inline,
	.sp-inline-block,
	.sp-block,
	.sp-flex {
		display: none !important;
	}
	
	.tb-inline {
		display: inline !important;
	}
	.tb-inline-block {
		display: inline-block !important;
	}
	.tb-block {
		display: block !important;
	}
	.tb-flex {
		display: flex !important;
	}
	
	.tb-only-inline {
		display: inline !important;
	}
	.tb-only-inline-block {
		display: inline-block !important;
	}
	.tb-only-block {
		display: block !important;
	}
	.tb-only-flex {
		display: flex !important;
	}
	
	.menu-br {
		display: inline !important;
	}
	
}
@media #{$pc} {
	.tb-only-inline,
	.tb-only-inline-block,
	.tb-only-block,
	.tb-only-flex {
		display: none !important;
	}
	
	.pc-inline {
		display: inline !important;
	}
	.pc-inline-block {
		display: inline-block !important;
	}
	.pc-block {
		display: block !important;
	}
	.pc-flex {
		display: flex !important;
	}
}
@media #{$large} {
	.menu-br {
		display: none !important;
	}
}

.issp {
	position: absolute;
	left: 0;
	top: 0;
	display: block;
	width: 1px;
	height: 1px;
	pointer-events: none;
	
	@media #{$tb} {
		display: none;
	}
}