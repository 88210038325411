.m-prevnext {
	display: flex;
	justify-content: space-between;
	margin-top: spvw(40);
	
	@media #{$tb} {
		margin-top: 40px;
	}
	&__prev,
	&__next {
		width: 50%;
		font-size: spvw(14);
		line-height: 1.7;
		
		@media #{$tb} {
			font-size: 14px;
		}
		> a {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: spvw(60);
			padding: 0 9%;
			box-sizing: border-box;
			border: 1px solid #ddd;
			
			@media #{$tb} {
				height: 108px;
			}
			> span {
				> span {
					&:nth-child(1) {
						@media #{$tb} {
							display: none;
						}
					}
					&:nth-child(2) {
						@media #{$sp} {
							display: none;
						}
					}
				}
			}
			&:before {
				position: absolute;
				top: calc( 50% - spvw(4) );
				width: spvw(8);
				height: spvw(8);
				border-top: 1px solid #666;
				border-left: 1px solid #666;
				display: block;
				content: '';
				
				@media #{$tb} {
					top: calc( 50% - 4px );
					width: 8px;
					height: 8px;
				}
			}
		}
	}
	&__prev {
		> a {
			padding-left: 16%;
			
			&:before {
				left: calc( 8% - spvw(4) );
				transform: rotate(-45deg);
				
				@media #{$tb} {
					left: calc( 8% - 4px );
				}
			}
		}
	}
	&__next {
		> a {
			padding-right: 16%;
			
			&.noborder {
				border-left: 0;
			}
			&:before {
				right: calc( 8% - spvw(4) );
				transform: rotate(135deg);
				
				@media #{$tb} {
					right: calc( 8% - 4px );
				}
			}
		}
	}
}