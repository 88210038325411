.m-author {
	display: flex;
	justify-content: space-between;
	padding: spvw(20);
	border: 1px solid #ddd;
	
	@media #{$tb} {
		padding: 40px;
	}
	&:not(:first-child) {
		margin-top: spvw(40);
		
		@media #{$tb} {
			margin-top: 40px;
		}
	}
	&__thumb {
		width: spvw(104);
		border-radius: spvw(52);
		overflow: hidden;
		
		@media #{$tb} {
			width: 120px;
			border-radius: 60px;
		}
		> a {
			display: block;
			
			> img {
				display: block;
				width: 100%;
			}
		}
	}
	&__info {
		width: calc( 100% - spvw(104) - spvw(24) );
		
		@media #{$tb} {
			width: calc( 100% - 120px - 40px );
		}
	}
	&__name {
		margin-bottom: spvw(15);
		font-size: spvw(16);
		font-weight: $fw-bold;
		line-height: 1;
		
		@media #{$tb} {
			margin-bottom: 20px;
			font-size: 18px;
		}
		> a {
			color: inherit;
			
			&:hover,
			&:active {
				color: #bf9d87;
				opacity: 1;
			}
		}
	}
	&__description {
		margin: -0.3em 0 0 0;
		max-height: 3.2em;
		font-size: spvw(13);
		line-height: 1.6;
		overflow: hidden;
		
		@media #{$tb} {
			font-size: 14px;
		}
		> span {
			display: -webkit-inline-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
		}
	}
	&__sns {
		display: flex;
		margin-top: spvw(12);
		font-size: spvw(16);
		line-height: 1;
		
		@media #{$tb} {
			margin-top: 15px;
			font-size: 18px;
		}
		> li {
			&:not(:last-child) {
				margin-right: 0.75em;
				
				@media #{$tb} {
					margin-right: 10px;
				}
			}
			> a {
				display: block;
				
				> i {
					font-style: normal;
					
					&:before {
						display: inline-block;
						font-family: $ff-icon;
					}
					&.ico-sns-website:before {
						content: '\f11f';
					}
					&.ico-sns-instagram:before {
						content: '\f12d';
					}
					&.ico-sns-x:before {
						font-family: $ff-awesome;
						font-weight: $fw-regular;
						content: '\e61b';
					}
					&.ico-sns-facebook:before {
						content: '\f304';
					}
					&.ico-sns-youtube:before {
						content: '\f19b';
					}
				}
			}
		}
	}
}