
/*===============================================
 footer
===============================================*/
.l-footer {
	padding: 30px 0;
	background-color: #F1EADF;
	
	&__copyright {
		text-align: center;
		font-size: spvw(12);
		font-family: $ff-serif;
		font-weight: $fw-medium;
		
		@media #{$tb} {
			font-size: 12px;
		}
	}
}
