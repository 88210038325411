.m-single {
	&__eyecatch {
		display: block;
		margin-bottom: spvw(40);
		margin-left: spvw(-20);
		margin-right: spvw(-20);
		
		@media #{$tb} {
			margin-bottom: 40px;
			margin-left: 0;
			margin-right: 0;
		}
		> img {
			display: block;
			width: 100%;
		}
	}
	&__heading {
		padding-bottom: spvw(20);
		padding-left: spvw(20);
		padding-right: spvw(20);
		border-bottom: 1px solid #ddd;
		margin-bottom: spvw(40);
		margin-left: spvw(-20);
		margin-right: spvw(-20);
		
		@media #{$tb} {
			padding-bottom: 40px;
			padding-left: 0;
			padding-right: 0;
			margin-bottom: 40px;
			margin-left: 0;
			margin-right: 0;
		}
	}
	&__info {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: spvw(20);
		
		@media #{$tb} {
			margin-bottom: 20px;
		}
	}
	&__pubdate,
	&__modified {
		font-size: spvw(14);
		
		@media #{$tb} {
			font-size: 14px;
		}
		&:before {
			display: inline-block;
			margin-right: 0.25em;
			vertical-align: -0.125em;
			font-family: $ff-icon;
		}
	}
	&__pubdate {
		&:before {
			content: "\f469";
		}
	}
	&__modified {
		margin-left: 1em;
		
		&:before {
			content: "\f321";
		}
	}
	&__cats {
		display: flex;
		margin-left: 1em;
		font-size: spvw(14);
		
		@media #{$tb} {
			font-size: 14px;
		}
		> li {
			margin-right: 0.75em;
			
			> a {
				color: #bf9d87;
			}
		}
	}
	&__ttl {
		font-size: spvw(20);
		line-height: 1.5;
		
		@media #{$tb} {
			font-size: 28px;
		}
	}
	&__body {
		h2 {
			margin: 0;
			margin-bottom: 1em;
			font-size: spvw(28);
			line-height: 1.4;
			
			@media #{$tb} {
				font-size: 28px;
			}
			&:not(:first-child) {
				margin-top: 3em;
			}
			
		}
		h3 {
			margin: 0;
			margin-bottom: 1em;
			font-size: spvw(22);
			line-height: 1.4;
			
			@media #{$tb} {
				font-size: 22px;
			}
			&:not(:first-child) {
				margin-top: 2em;
			}
			
		}
		h4 {
			margin: 0;
			margin-bottom: 1em;
			font-size: spvw(18);
			line-height: 1.4;
			
			@media #{$tb} {
				font-size: 18px;
			}
			&:not(:first-child) {
				margin-top: 2em;
			}
			
		}
		h5 {
			margin: 0;
			margin-bottom: 1em;
			font-size: spvw(15);
			line-height: 1.4;
			
			@media #{$tb} {
				font-size: 15px;
			}
			&:not(:first-child) {
				margin-top: 2em;
			}
		}
		p {
			margin: 0;
			font-size: spvw(15);
			line-height: 2.4;
			
			@media #{$tb} {
				font-size: 15px;
			}
			&:not(:first-child) {
				margin-top: 1em;
			}
		}
		ol,
		ul {
			font-size: spvw(15);
			line-height: 2.4;
			
			@media #{$tb} {
				font-size: 15px;
			}
			&:not(:first-child) {
				margin-top: 1em;
			}
		}
		ol {
			list-style: decimal;
			margin-block-start: 1em;
			margin-block-end: 1em;
			margin-inline-start: 0px;
			margin-inline-end: 0px;
			padding-inline-start: 40px;
		}
		ul {
			list-style: disc;
			margin-block-start: 1em;
			margin-block-end: 1em;
			margin-inline-start: 0px;
			margin-inline-end: 0px;
			padding-inline-start: 40px;
		}
		blockquote {
			padding: 1em;
			background-color: #F1EADF;
			font-size: spvw(15);
			line-height: 2.4;
			
			@media #{$tb} {
				font-size: 15px;
			}
			&:not(:first-child) {
				margin-top: 2em;
			}
			
			cite {
				font-size: spvw(13);
				
				@media #{$tb} {
					font-size: 13px;
				}
			}
		}
		a {
			color: #bf9d87;
		}
		.wp-block-image {
			&:not(:first-child) {
				margin-top: 2em;
			}
		}
	}
}