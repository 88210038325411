body {
	min-height: 100%;
	background-color: #fff;
	color: $fc-default;
	font-size: spvw(15);
	font-family: $ff-default;
	font-weight: $fw-regular;
	line-height: calc( 35 / 24 );
	// letter-spacing: 0.05em;
	-webkit-font-feature-settings: "palt" 1;
	font-feature-settings: "palt" 1;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	
	@media #{$tb} {
		font-size: 15px;
	}
	@media #{$pc} {
		font-size: 17px;
	}
}
