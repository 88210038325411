.m-share {
	margin-top: spvw(40);
	
	@media #{$tb} {
		margin-top: 40px;
	}
	> ul {
		display: flex;
		
		> li {
			width: calc( ( 100% - 10px * 5 ) / 6 );
			
			&:not(:first-child) {
				margin-left: 10px;
			}
			> a {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: spvw(36);
				border: 1px solid #dddddd;
				border-radius: spvw(5);
				
				@media #{$tb} {
					height: 33px;
					border-radius: 5px;
				}
				> i {
					display: block;
					line-height: 1;
					
					&:before {
						display: block;
						font-family: $ff-icon;
						font-style: normal;
					}
					&.ico-share-x:before {
						font-family: $ff-awesome;
						font-weight: $fw-regular;
						content: '\e61b';
					}
					&.ico-share-facebook:before {
						content: '\f304';
					}
					&.ico-share-hatena:before {
						content: 'B!';
						font-family: Verdana;
						font-weight: bold;
					}
					&.ico-share-pocket:before {
						font-family: $ff-awesome;
						font-weight: $fw-black;
						content: '\f265';
					}
					&.ico-share-rss:before {
						content: '\f303';
					}
					&.ico-share-pin:before {
						content: '\f192';
					}
				}
				> span {
					display: none;
					
					@media #{$tb} {
						display: inline-block;
						margin-left: 0.5em;
						font-size: 12px;
						line-height: 1;
					}
				}
				&:hover,
				&:active {
					opacity: 1;
					color: #fff;
					transition: background-color .2s;
				}
				&.bg-share-x:hover,
				&.bg-share-x:active {
					background-color: #000;
				}
				&.bg-share-facebook:hover,
				&.bg-share-facebook:active {
					background-color: #35629a;
				}
				&.bg-share-hatena:hover,
				&.bg-share-hatena:active {
					background-color: #3c7dd1;
				}
				&.bg-share-pocket:hover,
				&.bg-share-pocket:active {
					background-color: #ee4056;
				}
				&.bg-share-rss:hover,
				&.bg-share-rss:active {
					background-color: #ff8c00;
				}
				&.bg-share-pin:hover,
				&.bg-share-pin:active {
					background-color: #d4121c;
				}
			}
		}
	}
}