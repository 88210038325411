.m-pager {
	margin-top: spvw(40);
	
	@media #{$tb} {
		margin-top: 60px;
	}
	ul {
		display: flex;
		justify-content: center;
		line-height: 1;
		
		li {
			&:not(:first-child) {
				margin-left: 0.2em;
			}
			> span,
			> a {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 2em;
				height: 2em;
			}
			> a {
				background-color: #eee;
			}
			> span {
				background-color: #bf9d87;
				color: #fff;
			}
			&.ommit {
				> span {
					background-color: #eee;
					color: inherit;
				}
			}
			&.prev,
			&.next {
				> a {
					position: relative;
					
					&:before {
						font-size: 60%;
					}
				}
			}
			&.prev {
				> a {
					&:before {
						content: '＜';
					}
				}
			}
			&.next {
				> a {
					&:before {
						content: '＞';
					}
				}
			}
		}
	}
}