.m-widget {
	&-posts {
		border-left: 1px solid #ddd;
		border-right: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
		
		&-item {
			border-top: 1px solid #ddd;
			
			> a {
				display: flex;
			}
			&__thumb {
				width: spvw(110 * 1.15);
				
				@media #{$tb} {
					width: 110px;
				}
				> img {
					display: block;
				}
			}
			&__info {
				width: calc( 100% - spvw(110 * 1.15) );
				padding: spvw(10 * 1.15) spvw(20 * 1.15);
				
				@media #{$tb} {
					width: calc( 100% - 110px );
					padding: 10px 20px;
				}
			}
			&__ttl {
				font-size: spvw(14 * 1.15);
				font-weight: $fw-regular;
				line-height: 1.7;
				
				@media #{$tb} {
					font-size: 14px;
				}
			}
		}
	}
	&-cats {
		border-left: 1px solid #ddd;
		border-right: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
		font-size: spvw(14 * 1.15);
		font-weight: $fw-regular;
		line-height: 1.15;
		
		@media #{$tb} {
			font-size: 14px;
		}
		> li {
			border-top: 1px solid #ddd;
			
			> a {
				display: block;
				padding: spvw(20 * 1.15);
				
				@media #{$tb} {
					padding: 20px;
				}
			}
		}
	}
}