.m-effect {
	
	&-fadein {
		opacity: 0;
		transition: opacity .8s;
		
		.is-inview &,
		&.is-inview {
			opacity: 1;
		}
	}
	&-fadein-up {
		opacity: 0;
		transform: translateY(10px);
		transition: opacity .8s, transform .8s $ease-out-cubic;
		
		.is-inview &,
		&.is-inview {
			opacity: 1;
			transform: translateY(0px);
		}
	}
	@for $i from 1 through 20 {
		&-delay-#{($i * 100)}ms {
			.is-inview &,
			&.is-inview {
				transition-delay: #{($i * 100)}ms;
			}
		}
	}
}