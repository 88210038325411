/*===============================================
 common
===============================================*/
img {
	max-width: 100%;
	height: auto;
}

a {
	color: inherit;
	text-decoration: none;
	transition: opacity .1s linear;
	backface-visibility: hidden;
	cursor: default;

	&[href] {
		cursor: pointer;
		
		&:hover,
		&:active {
			opacity: 0.7;
		}
	}
}
button,
input[type="submit"] {
	transition: opacity .1s linear;
	
	&:hover,
	&:active {
		opacity: 0.7;
	}
}

.alt {
	display: none;
}
