/*===============================================
 header
===============================================*/
.l-header {
	position: fixed;
	left: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: spvw(72);
	background-color: #F1EADF;
	z-index: $z-header;
	
	@media #{$tb} {
		height: 72px;
	}
	@media #{$pc} {
		bottom: auto;
		top: 0;
	}
	@media #{$large} {
		height: 110px;
	}
	&__in {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: spvw(400);
	}
	&__logo {
		width: spvw(172);
		
		@media #{$tb} {
			width: 172px;
		}
		@media #{$large} {
			width: 232px;
		}
		a {
			display: block;
			
			img {
				display: block;
				width: 100%;
			}
		}
	}
	&__nav {
		display: none;
		
		@media #{$pc} {
			display: block;
		}
		ul {
			display: flex;
			white-space: nowrap;
			font-size: 14px;
			line-height: 1;
			
			@media #{$large} {
				font-size: 18px;
			}
			> li {
				&:not(:first-child) {
					margin-left: 26px;
					
					@media #{$large} {
						margin-left: 34px;
					}
				}
			}
		}
	}
	&__link {
		> a {
			display: inline-flex;
			align-items: center;
			height: spvw(41);
			padding: 0 spvw(16);
			border-radius: spvw(22);
			background-color: #000;
			color: #fff;
			font-size: spvw(13);
			font-weight: $fw-medium;
			
			@media #{$tb} {
				height: 41px;
				padding: 0 16px;
				border-radius: 22px;
				font-size: 13px;
			}
			@media #{$large} {
				height: 51px;
				padding: 0 40px;
				border-radius: 26px;
				font-size: 20px;
			}
		}
	}
	/*
	&__menu {
		position: absolute;
		right: spvw(8);
		top: spvw(22);
		
		@media #{$tb} {
			display: none;
		}
		&__btn {
			display: block;
			width: spvw(24);
			height: spvw(24);
			padding: 0;
			border: 0;
			background: transparent;
			cursor: pointer;
			
			.border {
				position: absolute;
				left: 0;
				top: 50%;
				width: 100%;
				height: spvw(2);
				background-color: $color-black;
				transition: transform .3s $ease-out-cubic;
				
				&:nth-child(1) {
					body.is-menu-visible & {
						opacity: 0;
					}
				}
				&:nth-child(2) {
					transform: translateY(#{spvw(-7)});
					
					body.is-menu-visible & {
						transform: translateY(0vw) rotate(195deg);
					}
				}
				&:nth-child(3) {
					transform: translateY(#{spvw(7)});
					
					body.is-menu-visible & {
						transform: translateY(0vw) rotate(-195deg);
					}
				}
			}
		}
	}
	*/
}
