.m-relative {
	margin-top: spvw(40);
	
	@media #{$tb} {
		margin-top: 40px;
	}
	&__ttl {
		margin-bottom: spvw(20 * 1.15);
		text-align: center;
		font-size: spvw(18 * 1.15);
		
		@media #{$tb} {
			margin-bottom: 20px;
			font-size: 18px;
		}
	}
	&__container {
		display: flex;
		flex-wrap: wrap;
	}
	&__item {
		@media #{$sp} {
			width: calc( ( 100% - spvw(20) ) / 2 );
			
			&:nth-child(n+3) {
				margin-top: spvw(20);
			}
			&:not(:nth-child(2n+1)) {
				margin-left: spvw(20);
			}
		}
		@media #{$tb} {
			width: calc( ( 100% - 20px * 2 ) / 3 );
			
			&:nth-child(n+4) {
				margin-top: 20px;
			}
			&:not(:nth-child(3n+1)) {
				margin-left: 20px;
			}
		}
		> a {
			display: block;
		}
		&__thumb {
			> img {
				display: block;
				width: 100%;
			}
		}
		&__info {
			margin-top: spvw(8);
			
			@media #{$tb} {
				margin-top: 8px;
			}
		}
		&__ttl {
			font-size: spvw(14 * 1.15);
			font-weight: $fw-regular;
			line-height: 1.7;
			
			@media #{$tb} {
				font-size: 14px;
			}
		}
	}
}