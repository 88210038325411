@charset "utf-8";

/*===============================================
 common
===============================================*/
.p-common {
	padding: spvw(40) spvw(20) spvw(80);
	
	@media #{$tb} {
		padding: 40px 20px 80px;
	}
	@media #{$pc} {
		padding: 80px 40px 160px;
	}
	&__in {
		max-width: #{$pc-in}px;
		margin-left: auto;
		margin-right: auto;
	}
	&__heading {
	}
	&__en {
		margin: 0;
		text-align: center;
		font-size: spvw(28);
		font-family: $ff-en;
		line-height: 1;
		
		@media #{$tb} {
			font-size: 36px;
		}
	}
	&__ttl {
		margin: 0;
		text-align: center;
		font-size: spvw(16);
		font-weight: $fw-regular;
		line-height: 1;
		
		@media #{$tb} {
			font-size: 16px;
		}
		&:not(:first-child) {
			margin-top: 0.5em;
		}
	}
	&__body {
		margin-top: spvw(24);
		
		@media #{$tb} {
			margin-top: 56px;
		}
		
		p {
			&:not(:first-child) {
				margin-top: calc( 1em * 35 / 24 );
			}
		}
		a {
			color: #724103;
			text-decoration: underline;
			
			&:hover {
				text-decoration: none;
			}
		}
	}
}