.m-side {
	@media #{$sp} {
		margin-top: spvw(40);
	}
	&__widgets {
		.widget {
			&:not(:first-child) {
				margin-top: spvw(40);
				
				@media #{$tb} {
					margin-top: 40px;
				}
			}
		}
		.widgettitle {
			margin-bottom: spvw(20 * 1.15);
			font-size: spvw(18 * 1.15);
			
			@media #{$tb} {
				margin-bottom: 20px;
				font-size: 18px;
			}
		}
	}
}