@charset "utf-8";

/*===============================================
 home
===============================================*/
.p-home {
	$pc-kv-width: 1980;
	$pc-kv-height: 798;
	
	&-kv {
		position: relative;
		width: 100%;
		
		&__slider {
			position: relative;
			height: calc( 100vh - spvw(72) );
			height: calc( 100lvh - spvw(72) );
			
			@media #{$tb} {
				height: 0;
				padding-top: calc( 100% * $pc-kv-height / $pc-kv-width );
			}
		}
		@keyframes anim-kv-loading-spin {
			from {
			  transform: rotate(0);
			}
			to {
			  transform: rotate(360deg);
			}
		}
		&__loading {
			position: absolute;
			left: calc( 50% - spvw(16) );
			top: calc( 50% + spvw(120) );
			width: spvw(32);
			height: spvw(32);
			border-radius: 50%;
			border: spvw(3) solid #ddd;
			border-top-color: #999;
			animation: anim-kv-loading-spin 1s infinite linear;
			
			@media #{$tb} {
				left: calc( 50% - 16px );
				top: calc( 50% - 16px );
				width: 32px;
				height: 32px;
				border-width: 3px;
			}
		}
		&__slide {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			overflow: hidden;
		}
		&__item {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			transition: opacity .2s, transform .8s;
			
			&.is-visible {
				opacity: 1;
				transform: scale(1.05);
				transition: opacity .8s, transform 8s linear;
			}
			> picture {
				position: absolute;
				left: 0;
				top: 0;
				display: block;
				width: 100%;
				height: 100%;
				overflow: hidden;
				
				> img {
					width: 100%;
					height: 100%;
					max-width: none;
					object-fit: cover;
					object-position: center center;
				}
			}
		}
		&__nav {
			position: absolute;
			left: 0;
			bottom: spvw(20);
			display: flex;
			justify-content: center;
			width: 100%;
			
			@media #{$tb} {
				position: relative;
				bottom: 0;
				margin-top: 26px;
			}
			> span {
				display: block;
				width: spvw(19);
				height: spvw(19);
				border: 1px solid #D1D1D1;
				border-radius: spvw(10);
				background-color: #D1D1D1;
				transform: scale(calc( 12 / 19 ));
				transition: background-color .2s, transform .2s;
				cursor: pointer;
				
				@media #{$tb} {
					width: 19px;
					height: 19px;
					border-width: 0;
					border-radius: 10px;
				}
				&:not(:first-child) {
					margin-left: spvw(14);
					
					@media #{$tb} {
						margin-left: 14px;
					}
				}
				&.is-active {
					background-color: #000;
					transform: scale(1);
					pointer-events: none;
				}
			}
		}
		&__logo {
			position: absolute;
			left: calc( 50% - spvw(127 * 0.5) );
			top: calc( 50% - spvw(134.81 * 0.5) );
			width: spvw(127);
			
			@media #{$tb} {
				left: calc( 100% * 84 / $pc-kv-width );
				top: calc( 100% * 72 / $pc-kv-height );
				width: calc( 100% * 182 / $pc-kv-width );
			}
			> img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
	}
	&-sect {
		&__ttl {
			display: block;
			text-align: center;
			
			.en {
				display: block;
				color: #724103;
				font-size: spvw(16);
				font-family: $ff-serif;
				font-weight: $fw-medium;
				line-height: 1;
				
				@media #{$tb} {
					font-size: 24px;
				}
				@media #{$pc} {
					font-size: 30px;
				}
			}
			.ja {
				display: block;
				margin-top: 0.5em;
				font-size: spvw(18);
				font-family: $ff-serif;
				font-weight: $fw-regular;
				line-height: 1.2;
				
				@media #{$tb} {
					font-size: 16px;
				}
				@media #{$pc} {
					font-size: 25px;
				}
			}
			.ja-catch {
				font-size: spvw(18);
				
				@media #{$tb} {
					font-size: 18px;
				}
				@media #{$pc} {
					font-size: 40px;
				}
				> span {
					font-size: spvw(14);
					font-family: $ff-serif;
					font-weight: $fw-regular;
					
					@media #{$tb} {
						font-size: 14px;
					}
					@media #{$pc} {
						font-size: 24px;
					}
				}
			}
		}
	}
	&-concept {
		padding: spvw(56) spvw(26) spvw(70);
		
		@media #{$tb} {
			padding: 56px 26px 70px;
		}
		@media #{$pc} {
			padding: 78px 85px 70px;
		}
		&__logo {
			display: block;
			width: spvw(117);
			margin: spvw(40) auto 0;
			
			@media #{$tb} {
				width: 117px;
				margin-top: 40px;
			}
			@media #{$pc} {
				width: 180px;
				margin-top: 18px;
			}
			> img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
		&__txt {
			margin-top: spvw(36);
			
			@media #{$tb} {
				margin-top: 36px;
			}
			@media #{$pc} {
				max-width: 1044px;
				margin-top: 26px;
				margin-left: auto;
				margin-right: auto;
			}
			> p {
				font-size: spvw(15);
				font-family: $ff-serif;
				font-weight: $fw-medium;
				line-height: 2;
				
				@media #{$tb} {
					font-size: 15px;
				}
				@media #{$pc} {
					font-size: 17px;
					line-height: calc( 30 / 17 );
				}
			}
		}
	}
	&-info {
		padding: spvw(42) 0 spvw(54);
		background-color: #F8F8F8;
		
		@media #{$tb} {
			padding: 35px 0 55px;
		}
		&__slider {
			margin-top: spvw(32);
			
			@media #{$tb} {
				margin-top: 32px;
			}
			@media #{$pc} {
				margin-top: 35px;
			}
		}
		&__slide {
			padding: 0 spvw(22);
			
			@media #{$tb} {
				padding: 0 26px;
			}
			@media #{$pc} {
				padding: 0 85px;
			}
		}
		&__item {
			flex: 0 0 auto;
			width: spvw(210);
			
			@media #{$tb} {
				width: 210px;
			}
			@media #{$pc} {
				width: 350px;
			}
			&:not(:first-child) {
				margin-left: spvw(26);
				
				@media #{$tb} {
					margin-left: 26px;
				}
				@media #{$pc} {
					margin-left: 48px;
				}
			}
			
			> a {
				display: block;
				color: #292929;
			}
			&__thumb {
				> img {
					display: block;
					width: 100%;
					height: auto;
				}
			}
			&__ttl {
				margin-top: spvw(10);
				font-size: spvw(14);
				font-weight: $fw-medium;
				line-height: calc( 20 / 14 );
				
				@media #{$tb} {
					margin-top: 10px;
					font-size: 14px;
				}
				@media #{$pc} {
					margin-top: 16px;
					font-size: 17px;
					line-height: calc( 30 / 17 );
				}
			}
		}
	}
	&-menu {
		padding: spvw(42) spvw(0) spvw(0);
		background-color: #F1EADF;
		
		@media #{$tb} {
			padding: 42px 0 0;
		}
		@media #{$pc} {
			padding: 56px 0 0;
		}
		&__container {
			margin-top: spvw(21);
			background-color: #ffffff;
			
			@media #{$tb} {
				margin-top: 21px;
				display: flex;
			}
			@media #{$pc} {
				margin-top: 45px;
			}
		}
		&__item {
			@media #{$tb} {
				flex: 0 0 auto;
				width: calc( 100% * 1 / 3 );
			}
			&__thumb {
				position: relative;
				display: block;
				
				> picture {
					display: block;
					width: 100%;
					
					> img {
						display: block;
						width: 100%;
						height: auto;
					}
				}
				> figcaption {
					position: absolute;
					left: 0;
					top: calc( 50% - 0.5em );
					width: 100%;
					text-align: center;
					color: #ffffff;
					font-size: spvw(40);
					font-family: $ff-en;
					font-weight: $fw-regular;
					white-space: nowrap;
					text-shadow: 0 spvw(3) spvw(6) rgba(0,0,0,0.5);
					
					@media #{$tb} {
						font-size: tbvw(32);
						text-shadow: 0 tbvw(3) tbvw(6) rgba(0,0,0,0.5);
					}
					@media #{$pc} {
						font-size: 40px;
						text-shadow: 0 3px 6px rgba(0,0,0,0.5);
					}
				}
			}
			&__info {
				padding: spvw(20) spvw(22) spvw(32);
				
				@media #{$tb} {
					padding: 16px 16px 32px;
				}
				@media #{$pc} {
					padding: 24px 16px 0;
				}
				@media #{$large} {
					padding: 24px 48px 0;
				}
			}
			&__ttl {
				text-align: center;
				color: #292929;
				font-size: spvw(20);
				font-family: $ff-serif;
				font-weight: $fw-bold;
				
				@media #{$tb} {
					font-size: 18px;
				}
				@media #{$pc} {
					font-size: 23px;
				}
			}
			&__txt {
				margin-top: spvw(16);
				color: #565656;
				font-size: spvw(15);
				line-height: calc( 26 / 15 );
				
				@media #{$tb} {
					margin-top: 8px;
					font-size: 13px;
				}
				@media #{$pc} {
					font-size: 15px;
				}
			}
		}
	}
	&-about {
		margin-top: spvw(10);
		
		@media #{$tb} {
			margin-top: 32px;
		}
		@media #{$pc} {
			margin-top: 80px;
		}
		&__catch {
			text-align: center;
			font-size: spvw(20);
			font-family: $ff-serif;
			font-weight: $fw-medium;
			line-height: calc( 29 / 20 );
			
			@media #{$tb} {
				font-size: 20px;
			}
			@media #{$pc} {
				font-size: 30px;
				line-height: calc( 44 / 30 );
			}
		}
		&__container {
			margin-top: spvw(32);
			
			@media #{$tb} {
				margin-top: 32px;
			}
			@media #{$pc} {
				display: flex;
				margin-top: 95px;
			}
		}
		&__img {
			display: block;
			
			@media #{$pc} {
				position: relative;
				width: calc( ( 100% - 643px ) / 2 );
			}
			> img {
				display: block;
				width: 100%;
				height: auto;
				
				@media #{$pc} {
					object-position: center center;
					object-fit: cover;
					height: 100%;
				}
			}
		}
		&__in {
			padding: spvw(36) spvw(22) spvw(32);
			background-color: #F1EADF;
			
			@media #{$tb} {
				padding: 36px 22px 32px;
			}
			@media #{$pc} {
				width: 643px;
				padding: 106px 64px;
				box-sizing: border-box;
			}
		}
		&__txt {
			margin-top: spvw(24);
			
			@media #{$tb} {
				margin-top: 24px;
			}
			> p {
				font-size: spvw(15);
				line-height: calc( 26 / 15 );
				
				@media #{$tb} {
					font-size: 15px;
				}
			}
		}
	}
	&-problem {
		padding: spvw(40) spvw(44.5) spvw(32);
		background: url(#{$img}home/problem-bg-s.webp) center top;
		background-size: cover;
		
		@media #{$tb} {
			padding: 40px 44px 32px;
			background-image: url(#{$img}home/problem-bg.webp);
		}
		@media #{$tb} {
			padding: 92px 60px 110px;
		}
		&__in {
			@media #{$tb} {
				max-width: 980px;
				margin-left: auto;
				margin-right: auto;
			}
		}
		&__ttl {
			text-align: center;
			color: #ffffff;
			font-size: spvw(25);
			font-family: $ff-serif;
			font-weight: $fw-light;
			
			@media #{$tb} {
				font-size: 25px;
			}
		}
		&__list {
			margin-top: spvw(18);
			padding: spvw(16) 0 spvw(22);
			border-top: 1px solid #ffffff;
			border-bottom: 1px solid #ffffff;
			
			@media #{$tb} {
				margin-top: 18px;
				padding: 16px 0 22px;
			}
			@media #{$pc} {
				margin-top: 20px;
				padding: 42px 0;
			}
			> ul {
				color: #ffffff;
				font-size: spvw(15);
				font-weight: $fw-regular;
				line-height: calc( 26 / 15 );
				
				@media #{$tb} {
					font-size: 15px;
				}
				@media #{$pc} {
					columns: 2;
				}
				> li {
					position: relative;
					padding-left: 0.8em;
					
					&:before {
						position: absolute;
						left: 0;
						top: 0.6em;
						width: spvw(4);
						height: spvw(4);
						border-radius: spvw(2);
						background-color: #ffffff;
						content: '';
						
						@media #{$tb} {
							width: 4px;
							height: 4px;
							border-radius: 2px;
						}
					}
					&:not(:first-child) {
						margin-top: spvw(12);
						
						@media #{$tb} {
							margin-top: 12px;
						}
					}
				}
			}
		}
		&__txt {
			margin-top: spvw(18);
			
			@media #{$tb} {
				margin-top: 18px;
			}
			@media #{$pc} {
				margin-top: 42px;
			}
			> p {
				color: #ffffff;
				font-size: spvw(18);
				font-family: $ff-serif;
				font-weight: $fw-regular;
				line-height: calc( 30 / 18 );
				
				@media #{$tb} {
					font-size: 18px;
				}
			}
		}
	}
	&-trainer {
		padding: spvw(48) spvw(22) spvw(72);
		background-color: #F1EADF;
		
		@media #{$tb} {
			padding: 48px 22px 72px;
		}
		@media #{$pc} {
			background: url(#{$img}home/trainer-bg.svg) no-repeat center center;
			background-size: cover;
		}
		&__lead {
			margin-top: spvw(26);
			
			@media #{$tb} {
				margin-top: 26px;
			}
			> p {
				text-align: center;
				font-size: spvw(16);
				font-family: $ff-serif;
				line-height: calc( 28 / 16 );
				
				@media #{$tb} {
					font-size: 16px;
				}
			}
		}
		&__container {
			margin-top: spvw(42);
			
			@media #{$tb} {
				margin-top: 42px;
			}
			@media #{$pc} {
				display: flex;
				flex-wrap: wrap;
			}
		}
		&__item {
			&.is-large {
				@media #{$pc} {
					display: flex;
					align-items: flex-end;
				}
			}
			&:not(.is-large) {
				@media #{$pc} {
					width: calc( 100% * 340 / $pc-in );
				}
				&:not(:nth-child(3n+2)) {
					margin-left: calc( 100% * 40 / $pc-in );
				}
			}
			&:not(:first-child) {
				margin-top: spvw(48);
				
				@media #{$tb} {
					margin-top: 48px;
				}
			}
			&__thumb {
				position: relative;
				
				@media #{$tb} {
					max-width: 390px;
					margin: 0 auto;
				}
				@media #{$pc} {
					max-width: 100%;
				}
				> img {
					display: block;
					width: 100%;
					height: auto;
				}
			}
			&.is-large &__thumb {
				@media #{$pc} {
					width: calc( 100% * 512 / $pc-in );
					margin: 0 auto 0 0;
				}
			}
			&__link {
				position: absolute;
				left: 50%;
				bottom: spvw(20);
				transform: translateX(-50%);
				
				@media #{$tb} {
					bottom: 20px;
				}
				> a {
					display: inline-flex;
					align-items: center;
					height: spvw(43);
					padding: 0 spvw(28) 0 spvw(40);
					background-color: #606060;
					border: 1px solid #707070;
					border-radius: spvw(22);
					white-space: nowrap;
					color: #ffffff;
					font-size: spvw(17);
					font-weight: $fw-medium;
					line-height: 1;
					
					@media #{$tb} {
						height: 43px;
						padding: 0 28px 0 40px;
						border-radius: 22px;
						font-size: 17px;
					}
					&:after {
						display: inline-block;
						width: spvw(28);
						height: spvw(20);
						margin-left: spvw(20);
						background: url(#{$img}common/ico-youtube.svg) no-repeat;
						background-size: 100% auto;
						vertical-align: middle;
						content: '';
						
						@media #{$tb} {
							width: 28px;
							height: 20px;
							margin-left: 20px;
						}
					}
				}
			}
			&.is-large &__link {
				@media #{$pc} {
					display: none;
				}
			}
			&__info {
				margin-top: spvw(24);
				
				@media #{$tb} {
					margin-top: 24px;
				}
			}
			&.is-large &__info {
				@media #{$pc} {
					width: calc( 100% * 536 / $pc-in );
				}
			}
			&__heading {
				display: flex;
				align-items: flex-end;
			}
			&__name {
				margin-right: 1em;
				font-size: spvw(26);
				font-family: $ff-serif;
				font-weight: $fw-medium;
				line-height: 1;
				
				@media #{$tb} {
					font-size: 26px;
				}
			}
			&__position {
				font-size: spvw(16);
				font-family: $ff-serif;
				font-weight: $fw-medium;
				line-height: 1;
				
				@media #{$tb} {
					font-size: 16px;
				}
			}
			&__txt {
				margin-top: spvw(20);
				font-size: spvw(15);
				font-family: $ff-serif;
				line-height: calc( 26 / 15 );
				
				@media #{$tb} {
					margin-top: 20px;
					font-size: 15px;
				}
			}
			&__video {
				display: none;
				
				@media #{$pc} {
					display: block;
					margin-top: 32px;
				}
				> a {
					position: relative;
					display: block;
					
					> img {
						display: block;
					}
					&:after {
						position: absolute;
						left: calc( 50% - 39px );
						top: calc( 50% - 27.5px );
						width: 78px;
						height: 55px;
						background: url(#{$img}common/ico-youtube.svg) no-repeat;
						background-size: 100% auto;
						content: '';
					}
				}
			}
		}
	}
	&-support {
		padding: spvw(56) spvw(36) spvw(36);
		background: url(#{$img}home/support-bg-s.webp) no-repeat center center;
		background-size: cover;
		
		@media #{$tb} {
			padding: 56px 36px 36px;
			background-image: url(#{$img}home/support-bg.webp);
		}
		@media #{$pc} {
			padding: 190px 36px;
		}
		&__txt {
			color: #fff;
			font-size: spvw(16);
			font-family: $ff-serif;
			font-weight: $fw-medium;
			line-height: calc( 30 / 16 );
			text-shadow: 0 spvw(3) spvw(6) rgba(0,0,0,0.5);
			
			@media #{$tb} {
				font-size: 16px;
				text-shadow: 0 3px 6px rgba(0,0,0,0.5);
			}
			@media #{$pc} {
				font-size: 20px;
				line-height: calc( 40 / 20 );
			}
		}
	}
	&-features {
		padding: spvw(42) 0 spvw(64);
		background-color: #F1EADF;
		
		@media #{$tb} {
			padding: 42px 0 64px;
		}
		&__container {
			position: relative;
			margin-top: spvw(32);
			
			@media #{$tb} {
				margin-top: 32px;
			}
		}
		&__slider {
			overflow: hidden;
			overflow-x: scroll;
			@include scrollbar-hidden;
			scroll-behavior: smooth;
		}
		&__slide {
			display: inline-flex;
			padding: 0 spvw(45);
			
			@media #{$tb} {
				padding: 0 75px;
			}
		}
		&__item {
			flex: 0 0 auto;
			width: spvw(432 - 90);
			
			@media #{$tb} {
				width: calc( 405px * 0.75 );
			}
			@media #{$large} {
				width: 405px;
			}
			&:not(:first-child) {
				margin-left: spvw(45);
				
				@media #{$tb} {
					margin-left: 30px;
				}
				@media #{$large} {
					margin-left: 54px;
				}
			}
			&__thumb {
				position: relative;
				display: block;
				
				> img {
					display: block;
					width: 100%;
					height: auto;
				}
			}
			&__ttl {
				display: flex;
				align-items: center;
				justify-content: center;
				height: spvw(52);
				margin-top: spvw(20);
				text-align: center;
				color: #292929;
				font-size: spvw(18);
				font-family: $ff-serif;
				font-weight: $fw-bold;
				line-height: calc( 26 / 18 );
				
				@media #{$tb} {
					height: 52px;
					margin-top: 20px;
					font-size: 18px;
				}
			}
			&__txt {
				margin-top: spvw(12);
				color: #565656;
				font-size: spvw(15);
				line-height: calc( 26 / 15 );
				
				@media #{$tb} {
					margin-top: 12px;
					font-size: 15px;
				}
			}
		}
		&__prev,
		&__next {
			position: absolute;
			top: spvw(160);
			display: block;
			appearance: none;
			width: spvw(28 * 0.75);
			height: spvw(49 * 0.75);
			padding: 0;
			border: 0;
			margin: 0;
			background: transparent url(#{$img}common/ico-slide.svg) no-repeat;
			background-size: 100% auto;
			cursor: pointer;
			
			@media #{$tb} {
				top: 140px;
				width: 28px;
				height: 49px;
			}
			@media #{$large} {
				top: 180px;
			}
			&:hover,
			&:active {
				opacity: 1 !important;
			}
			&[disabled] {
				opacity: 0.2 !important;
				pointer-events: none;
			}
		}
		&__prev {
			left: spvw(12);
			transform: scaleX(-1);
			
			@media #{$tb} {
				left: 20px;
			}
		}
		&__next {
			right: spvw(12);
			
			@media #{$tb} {
				right: 20px;
			}
		}
	}
	&-price {
		padding: spvw(42) spvw(14) spvw(48);
		
		@media #{$tb} {
			padding: 42px 14px 48px;
		}
		&__container {
			margin-top: spvw(36);
			
			@media #{$tb} {
				margin-top: 36px;
			}
		}
		&__item {
			border-radius: spvw(8);
			background-color: #F6F6F6;
			overflow: hidden;
			
			@media #{$tb} {
				border-radius: 8px;
			}
			&:not(:first-child) {
				margin-top: spvw(32);
				
				@media #{$tb} {
					margin-top: 32px;
				}
			}
			&__ttl {
				padding: spvw(12);
				background-color: #B19879;
				text-align: center;
				color: #ffffff;
				font-size: spvw(20);
				font-weight: $fw-medium;
				
				@media #{$tb} {
					padding: 12px;
					font-size: 20px;
				}
			}
			&__detail {
				padding: spvw(24);
				
				@media #{$tb} {
					padding: 24px;
				}
			}
			&__columns {
				@media #{$pc} {
					display: flex;
				}
			}
			&__column {
				@media #{$pc} {
					width: 50%;
					box-sizing: border-box;
				}
				&:not(:first-child) {
					margin-top: spvw(24);
					padding-top: spvw(24);
					border-top: 1px solid #c1c1c1;
					
					@media #{$tb} {
						margin-top: 24px;
						padding-top: 24px;
					}
					@media #{$pc} {
						margin-top: 0;
						padding-top: 0;
						border-top: 0;
						border-left: 1px solid #c1c1c1;
					}
				}
			}
			&__price {
				display: block;
				text-align: center;
				font-size: spvw(20);
				font-weight: $fw-regular;
				
				@media #{$tb} {
					font-size: 20px;
				}
				small {
					font-size: spvw(16);
					
					@media #{$tb} {
						font-size: 16px;
					}
				}
			}
			&__sub {
				text-align: center;
				font-size: spvw(20);
				font-weight: $fw-regular;
				
				@media #{$tb} {
					font-size: 20px;
				}
			}
			&__txt {
				margin-top: spvw(8);
				text-align: center;
				font-size: spvw(15);
				font-weight: $fw-medium;
				
				@media #{$tb} {
					margin-top: 8px;
					font-size: 15px;
				}
			}
			&__table {
				margin: 0 spvw(-24) spvw(-24);
				
				@media #{$tb} {
					margin: 0 -24px -24px;
				}
				table {
					display: block;
					
					@media #{$pc} {
						display: table;
						width: 100%;
					}
					tbody {
						display: block;
						
						@media #{$pc} {
							display: table-row-group;
						}
					}
					tr {
						display: block;
						padding: spvw(24);
						
						@media #{$tb} {
							padding: 24px;
						}
						@media #{$pc} {
							display: table-row;
						}
						&:nth-child(2n) {
							background-color: #EBEBEB;
						}
					}
					th,
					td {
						display: block;
						text-align: center;
						
						@media #{$pc} {
							display: table-cell;
							padding-top: 16px;
							padding-bottom: 16px;
							text-align: left;
						}
					}
					th {
						margin-bottom: spvw(10);
						font-size: spvw(20);
						font-weight: $fw-regular;
						
						@media #{$tb} {
							margin-bottom: 10px;
							font-size: 20px;
						}
						@media #{$pc} {
							padding-left: 40px;
							margin-bottom: 0;
						}
					}
					td {
						font-size: spvw(20);
						font-weight: $fw-regular;
						
						@media #{$tb} {
							font-size: 20px;
						}
						&:nth-child(3) {
							margin-top: spvw(4);
							
							@media #{$tb} {
								margin-top: 4px;
							}
							@media #{$pc} {
								margin-top: 0;
							}
						}
						small {
							font-size: spvw(16);
							
							@media #{$tb} {
								font-size: 16px;
							}
						}
					}
				}
			}
			&__notes {
				display: flex;
				justify-content: center;
				
				&:not(:first-child) {
					margin-top: spvw(12);
					
					@media #{$tb} {
						margin-top: 12px;
					}
				}
				&:not(:last-child) {
					margin-bottom: spvw(12);
					
					@media #{$tb} {
						margin-bottom: 12px;
					}
				}
				ul {
					font-size: spvw(14);
					line-height: calc( 22 / 14 );
					
					@media #{$tb} {
						font-size: 14px;
					}
					li {
						padding-left: 1em;
						text-indent: -1em;
						
						small {
							font-size: spvw(12);
							
							@media #{$tb} {
								font-size: 12px;
							}
						}
					}
				}
			}
			&__table &__notes {
				@media #{$pc} {
					justify-content: flex-start;
				}
			}
		}
		&__compare {
			margin-top: spvw(32);
			margin-left: spvw(-14);
			margin-right: spvw(-14);
			
			@media #{$tb} {
				margin-top: 32px;
				margin-left: -14px;
				margin-right: -14px;
			}
			@media #{$pc} {
				margin-top: 56px;
				margin-left: 0;
				margin-right: 0;
			}
			&__ttl {
				text-align: center;
				font-size: spvw(18);
				font-family: $ff-serif;
				font-weight: $fw-medium;
				
				@media #{$tb} {
					font-size: 18px;
				}
				@media #{$pc} {
					font-size: 25px;
				}
			}
			&__container {
				margin-top: spvw(20);
				
				@media #{$tb} {
					margin-top: 20px;
				}
			}
			&__slider {
				overflow: hidden;
				overflow-x: auto;
			}
			&__slide {
				display: inline-block;
				padding-left: spvw(14);
				padding-right: spvw(14);
				
				@media #{$tb} {
					min-width: calc( 100% - 28px );
					padding-left: 14px;
					padding-right: 14px;
				}
				@media #{$pc} {
					padding-left: 0;
					padding-right: 0;
				}
			}
			&__table {
				width: spvw(680);
				border: 1px solid #707070;
				border-radius: spvw(8);
				overflow: hidden;
				
				@media #{$tb} {
					width: 680px;
					margin-left: auto;
					margin-right: auto;
					border-radius: 8px;
				}
				@media #{$pc} {
					width: 100%;
				}
				table {
					@media #{$pc} {
						width: 100%;
					}
					thead {
						th {
							position: relative;
							background-color: #e4ddd5;
							padding-top: spvw(17);
							padding-bottom: spvw(17);
							font-size: spvw(12);
							font-weight: $fw-bold;
							
							@media #{$tb} {
								padding-top: 17px;
								padding-bottom: 17px;
								font-size: 12px;
							}
							@media #{$pc} {
								font-size: 20px;
							}
							&:nth-child(2) {
								width: spvw(200);
								border-left: spvw(4) solid #584f20;
								border-right: spvw(4) solid #584f20;
								background-color: #B19879;
								color: #ffffff;
								
								@media #{$tb} {
									width: 200px;
									border-left-width: 4px;
									border-right-width: 4px;
								}
								@media #{$pc} {
									width: 290px;
								}
								&:before {
									position: absolute;
									left: 0;
									top: -1px;
									width: 100%;
									height: spvw(4);
									background-color: #584f20;
									content: '';
									
									@media #{$tb} {
										height: 4px;
									}
								}
							}
							&:nth-child(3),
							&:nth-child(4) {
								width: spvw(180);
								
								@media #{$tb} {
									width: 180px;
								}
								@media #{$pc} {
									width: 290px;
								}
							}
							&:nth-child(4) {
								width: spvw(180);
								border-left: 1px solid #b5b5b5;
								
								@media #{$tb} {
									width: 180px;
								}
								@media #{$pc} {
									width: 280px;
								}
							}
						}
					}
					tbody {
						th,
						td {
							position: relative;
							background-color: #f6f6f6;
							border-top: 1px solid #b5b5b5;
						}
						th {
							padding: spvw(13) spvw(36);
							vertical-align: middle;
							text-align: left;
							font-size: spvw(12);
							font-weight: $fw-medium;
							white-space: nowrap;
							
							@media #{$tb} {
								padding: 13px 36px;
								font-size: 12px;
							}
							@media #{$pc} {
								padding: 26px 42px;
								font-size: 20px;
							}
						}
						td {
							padding: spvw(13) spvw(13);
							font-size: spvw(12);
							font-weight: $fw-regular;
							
							@media #{$tb} {
								padding: 13px 13px;
								font-size: 12px;
							}
							@media #{$pc} {
								padding: 26px 26px;
								text-align: center;
								font-size: 20px;
							}
							&:nth-child(2) {
								border-left: spvw(4) solid #584f20;
								border-right: spvw(4) solid #584f20;
								
								@media #{$tb} {
									border-left-width: 4px;
									border-right-width: 4px;
								}
							}
							&:nth-child(4) {
								border-left: 1px solid #b5b5b5;
							}
							ul {
								margin-left: -0.5em;
								
								@media #{$pc} {
									text-align: left;
									font-size: 14px;
								}
							}
							li {
								padding-left: 0.75em;
								text-indent: -0.75em;
								
								&:before {
									content: '・ ';
								}
							}
							p {
								@media #{$pc} {
									text-align: left;
									font-size: 14px;
								}
							}
						}
						tr:last-child td:nth-child(2) {
							&:after {
								position: absolute;
								left: 0;
								bottom: -1px;
								width: 100%;
								height: spvw(4);
								background-color: #584f20;
								content: '';
								
								@media #{$tb} {
									height: 4px;
								}
							}
						}
					}
				}
			}
		}
	}
	&-review {
		padding: spvw(52) 0 0 0;
		background-color: #F1EADF;
		
		@media #{$tb} {
			padding: 52px 0 0 0;
		}
		@media #{$large} {
			padding: 124px 0 0 0;
		}
		&__container {
			margin-top: spvw(28);
			
			@media #{$tb} {
				margin-top: 28px;
			}
			@media #{$pc} {
				display: flex;
				flex-wrap: wrap;
				width: calc( 100% * 1680 / 1920 );
				margin-left: auto;
				margin-right: auto;
				margin-top: 52px;
			}
		}
		&__item {
			@media #{$tb} {
				max-width: 430px;
				margin-left: auto;
				margin-right: auto;
			}
			@media #{$pc} {
				max-width: 100%;
				width: calc( 100% * 795 / 1680 );
				margin-left: 0;
				margin-right: 0;
			}
			&:not(:first-child) {
				margin-top: spvw(35);
				
				@media #{$tb} {
					margin-top: 35px;
				}
				@media #{$pc} {
					margin-top: 0;
				}
			}
			&:not(:nth-child(2n+1)) {
				@media #{$pc} {
					margin-left: calc( 100% * 90 / 1680 );
				}
			}
			&__comment {
				text-align: center;
				font-size: spvw(15);
				font-family: $ff-serif;
				font-weight: $fw-medium;
				
				@media #{$tb} {
					font-size: 15px;
				}
				@media #{$pc} {
					font-size: 18px;
				}
				@media #{$large} {
					font-size: 25px;
				}
			}
			&__video {
				margin-top: spvw(16);
				
				@media #{$tb} {
					margin-top: 16px;
				}
				> a {
					position: relative;
					display: block;
					
					> img {
						display: block;
						width: 100%;
						height: auto;
					}
					&:after {
						position: absolute;
						left: calc( 50% - spvw(48 * 0.5) );
						top: calc( 50% - spvw(34 * 0.5) );
						width: spvw(48);
						height: spvw(34);
						background: url(#{$img}common/ico-youtube.svg) no-repeat;
						background-size: 100% auto;
						content: '';
						
						@media #{$tb} {
							left: calc( 50% - 24px );
							top: calc( 50% - 17px );
							width: 48px;
							height: 34px;
						}
						@media #{$pc} {
							left: calc( 50% - 39px );
							top: calc( 50% - 27.5px );
							width: 78px;
							height: 55px;
						}
					}
				}
			}
		}
		&__result {
			margin-top: spvw(40);
			
			@media #{$tb} {
				margin-top: 40px;
			}
			@media #{$pc} {
				margin-top: 92px;
			}
			&__ttl {
				text-align: center;
				font-size: spvw(18);
				font-family: $ff-serif;
				font-weight: $fw-medium;
				
				@media #{$tb} {
					font-size: 18px;
				}
				@media #{$pc} {
					font-size: 25px;
				}
			}
			&__txt {
				margin-top: spvw(5);
				text-align: center;
				font-size: spvw(15);
				font-family: $ff-serif;
				font-weight: $fw-medium;
				
				@media #{$tb} {
					margin-top: 5px;
					font-size: 15px;
				}
				@media #{$pc} {
					margin-top: 7px;
					font-size: 16px;
				}
			}
			&__img {
				display: block;
				margin-top: spvw(35);
				
				@media #{$tb} {
					margin-top: 35px;
				}
				> img {
					display: block;
					width: 100%;
					height: auto;
				}
			}
		}
	}
	&-faq {
		padding: spvw(35) spvw(15) spvw(56);
		
		@media #{$tb} {
			padding: 35px 15px 56px;
		}
		@media #{$pc} {
			padding: 74px 15px 185px;
		}
		&__container {
			margin-top: spvw(30);
			
			@media #{$tb} {
				margin-top: 30px;
			}
		}
		&__lead {
			padding-left: spvw(15);
			padding-right: spvw(15);
			font-size: spvw(16);
			font-family: $ff-serif;
			font-weight: $fw-regular;
			line-height: calc( 27 / 16 );
			
			@media #{$tb} {
				padding-left: 15px;
				padding-right: 15px;
				font-size: 16px;
			}
			@media #{$pc} {
				padding-left: 0;
				padding-right: 0;
			}
		}
		&__detail {
			margin-top: spvw(50);
			
			@media #{$tb} {
				margin-top: 50px;
			}
		}
		&__item {
			border-bottom: 1px solid #cecece;
			
			&__question {
				position: relative;
				padding-top: spvw(24);
				padding-bottom: spvw(24);
				padding-right: spvw(24);
				list-style: none;
				font-size: spvw(15);
				font-family: $ff-serif;
				font-weight: $fw-semibold;
				cursor: pointer;
				
				@media #{$tb} {
					padding-top: 24px;
					padding-bottom: 24px;
					padding-right: 24px;
					font-size: 15px;
				}
				@media #{$pc} {
					padding-left: 24px;
					padding-right: 48px;
					font-size: 16px;
				}
				&::-webkit-details-marker {
					display:none;
				}
				&:before,
				&:after {
					position: absolute;
					right: 0;
					top: 50%;
					display: block;
					width: spvw(21);
					height: 1px;
					background-color: #331313;
					content: '';
					
					@media #{$tb} {
						width: 21px;
					}
					@media #{$pc} {
						width: 27px;
						height: 2px;
					}
				}
				&:after {
					transform: rotate(-90deg);
					transition: opacity .3s, transform .3s $ease-out-cubic;
				}
			}
			&[open] &__question {
				&:after {
					transform: rotate(0deg);
					opacity: 0;
				}
			}
			&__answer {
				padding-bottom: spvw(24);
				
				@media #{$tb} {
					padding-bottom: 24px;
				}
				@media #{$pc} {
					padding-left: 24px;
					padding-right: 48px;
				}
				p,
				dl {
					font-size: spvw(15);
					font-family: $ff-serif;
					line-height: calc( 26 / 15 );
					
					@media #{$tb} {
						font-size: 15px;
					}
					&:not(:first-child) {
						margin-top: 1.5em;
					}
				}
				dl {
					dt {
						position: relative;
						padding-left: 0.7em;
						font-weight: $fw-semibold;
						
						&:before {
							position: absolute;
							left: 0;
							top: 0.7em;
							width: 0.2em;
							height: 0.2em;
							border-radius: 0.1em;
							background-color: #000000;
							content: '';
						}
					}
					dd {
						margin-top: 0.5em;
					}
				}
			}
		}
	}
	&-message {
		padding: spvw(48) spvw(28) spvw(64);
		background-color: #F1EADF;
		
		@media #{$tb} {
			padding: 48px 28px 64px;
		}
		@media #{$pc} {
			padding: 70px 28px 75px;
		}
		&__container {
			margin-top: spvw(32);
			
			@media #{$tb} {
				margin-top: 32px;
			}
			@media #{$pc} {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}
		&__pic {
			> picture {
				display: block;
				width: spvw(301);
				border-radius: spvw(301 * 0.5);
				margin: 0 auto;
				overflow: hidden;
				
				@media #{$tb} {
					width: 301px;
					border-radius: 151px;
				}
				@media #{$pc} {
					width: 370px;
					border-radius: 185px;
				}
				> img {
					display: block;
					width: 100%;
					height: auto;
				}
			}
			figcaption {
				margin-top: spvw(20);
				
				@media #{$tb} {
					margin-top: 20px;
				}
				@media #{$pc} {
					margin-top: 24px;
				}
				> dl {
					display: flex;
					justify-content: center;
					align-items: flex-end;
					
					> dt {
						margin-right: 0.5em;
						font-size: spvw(14);
						line-height: 1;
						
						@media #{$tb} {
							font-size: 14px;
						}
						@media #{$pc} {
							font-size: 18px;
						}
						&:after {
							content: ' ：';
						}
					}
					> dd {
						font-size: spvw(20);
						font-family: $ff-serif;
						font-weight: $fw-medium;
						line-height: 1;
						
						@media #{$tb} {
							font-size: 20px;
						}
						@media #{$pc} {
							font-size: 26px;
						}
					}
				}
			}
		}
		&__txt {
			margin-top: spvw(32);
			
			@media #{$tb} {
				margin-top: 32px;
			}
			@media #{$pc} {
				width: calc( 100% - 370px - 68px );
				margin-top: 0;
			}
			> p {
				font-size: spvw(16);
				line-height: calc( 30 / 16 );
				
				@media #{$tb} {
					font-size: 16px;
				}
			}
		}
	}
	&-catch {
		padding: spvw(26) spvw(20) spvw(32);
		
		@media #{$tb} {
			padding: 26px 20px 32px;
		}
		@media #{$pc} {
			padding: 100px 20px 72px;
		}
		&__main {
			display: block;
			text-align: center;
			font-size: spvw(15);
			font-family: $ff-serif;
			font-weight: $fw-medium;
			line-height: calc( 26 / 15 );
			
			@media #{$tb} {
				font-size: 15px;
			}
			@media #{$pc} {
				font-size: 23px;
				line-height: calc( 33 / 23 );
			}
		}
		&__txt {
			margin-top: spvw(21);
			
			@media #{$tb} {
				margin-top: 21px;
			}
			@media #{$pc} {
				margin-top: 24px;
			}
			p {
				font-size: spvw(13);
				line-height: 2;
				
				@media #{$tb} {
					font-size: 13px;
				}
				@media #{$pc} {
					font-size: 14px;
				}
			}
		}
	}
	&-blog {
		padding: spvw(42) 0 spvw(54);
		background-color: #F8F8F8;
		
		@media #{$tb} {
			padding: 42px 0 54px;
		}
		@media #{$pc} {
			padding: 35px 0 55px;
		}
		&__slider {
			margin-top: spvw(32);
			
			@media #{$tb} {
				margin-top: 32px;
			}
			@media #{$pc} {
				margin-top: 35px;
			}
		}
		&__slide {
			padding: 0 spvw(22);
			
			@media #{$tb} {
				padding: 0 26px;
			}
			@media #{$pc} {
				padding: 0 85px;
			}
		}
		&__item {
			flex: 0 0 auto;
			width: spvw(210);
			
			@media #{$tb} {
				width: 210px;
			}
			@media #{$pc} {
				width: 210px;
			}
			&:not(:first-child) {
				margin-left: spvw(26);
				
				@media #{$tb} {
					margin-left: 26px;
				}
				@media #{$pc} {
					margin-left: 32px;
				}
			}
			
			> a {
				display: block;
				color: #292929;
			}
			&__thumb {
				> img {
					display: block;
					width: 100%;
					height: auto;
				}
			}
			&__ttl {
				margin-top: spvw(10);
				font-size: spvw(14);
				font-weight: $fw-medium;
				line-height: calc( 20 / 14 );
				
				@media #{$tb} {
					margin-top: 10px;
					font-size: 13px;
				}
				@media #{$pc} {
					margin-top: 16px;
					font-size: 14px;
					line-height: calc( 30 / 17 );
				}
			}
		}
	}
	&-video {
		padding: spvw(42) 0 spvw(54);
		
		@media #{$tb} {
			padding: 42px 0 54px;
		}
		@media #{$pc} {
			padding: 35px 0 55px;
		}
		&__slider {
			margin-top: spvw(32);
			
			@media #{$tb} {
				margin-top: 32px;
			}
			@media #{$pc} {
				margin-top: 35px;
			}
		}
		&__slide {
			padding: 0 spvw(22);
			
			@media #{$tb} {
				padding: 0 26px;
			}
			@media #{$pc} {
				padding: 0 85px;
			}
		}
		&__item {
			flex: 0 0 auto;
			width: spvw(210);
			
			@media #{$tb} {
				width: 210px;
			}
			@media #{$pc} {
				width: 300px;
			}
			&:not(:first-child) {
				margin-left: spvw(26);
				
				@media #{$tb} {
					margin-left: 26px;
				}
				@media #{$pc} {
					margin-left: 40px;
				}
			}
			
			> a {
				display: block;
				color: #292929;
			}
			&__thumb {
				> img {
					display: block;
					width: 100%;
					height: auto;
				}
			}
			&__ttl {
				margin-top: spvw(10);
				font-size: spvw(14);
				font-weight: $fw-medium;
				line-height: calc( 20 / 14 );
				
				@media #{$tb} {
					margin-top: 10px;
					font-size: 13px;
				}
				@media #{$pc} {
					margin-top: 16px;
					font-size: 14px;
					line-height: calc( 30 / 17 );
				}
			}
		}
	}
	&-access {
		padding: spvw(40) 0 0 0;
		background-color: #F1EADF;
		
		@media #{$tb} {
			padding: 40px 0 0 0;
		}
		&__body {
			margin: spvw(21) spvw(46) 0;
			
			@media #{$tb} {
				margin: 21px 46px 0;
			}
		}
		&__container {
			@media #{$pc} {
				display: flex;
				flex-wrap: wrap;
			}
			address,
			dl {
				font-size: spvw(14);
				font-style: normal;
				font-weight: $fw-regular;
				line-height: calc( 25 / 14 );
				
				@media #{$tb} {
					margin-right: 1em;
					font-size: 14px;
				}
			}
			dl {
				display: flex;
				
				dt {
					&:after {
						margin-right: 0.5em;
						content: ' ：';
					}
				}
			}
		}
		&__map {
			position: relative;
			margin-top: spvw(28);
			padding-top: calc( 100% * 704 / 430 );
			
			@media #{$tb} {
				margin-top: 28px;
				padding-top: 430px;
			}
			> iframe {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
	&-contact {
		padding: spvw(34) spvw(22) spvw(175);
		
		@media #{$tb} {
			padding: 34px 22px 175px;
		}
		&__container {
			margin-top: spvw(34);
			
			@media #{$tb} {
				margin-top: 34px;
			}
		}
	}
}