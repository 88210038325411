$color-black: #000000;
$color-white: #ffffff;

$color-text-black: #1A1A1A;
$color-text-white: #ffffff;
$color-text-gray: #4E4E4E;
$color-text-red: #c50018;
$color-text-red2: #CE0000;

$color-border-black: #1A1A1A;
$color-border-white: #FFFFFF;
$color-border-red: #ED4F4F;
$color-border-blue: #384C8A;
$color-border-gold: #947340;
$color-border-gray: #EAEAEA;

$color-bg-body: #f9f8f4;
$color-bg-black: #000000;
$color-bg-white: #ffffff;
$color-bg-cream01: #F2EFE7;
$color-bg-cream02: #F9F8F4;
$color-bg-cream: #FFE6A5;
$color-bg-yellow: #FDE82E;
$color-bg-yellow2: #FDEBBD;
$color-bg-gold: #947340;
$color-bg-red: #ff3333;
$color-bg-red2: #e00010;
$color-bg-red3: #C50018;
$color-bg-red4: #CE0000;
$color-bg-red5: #E43737;
$color-bg-blue: #384C8A;
$color-bg-gray: #767676;
$color-bg-gray2: #CCCCCC;